import React from 'react';

import Layout from '../components/layout';

const IndexPage = () => (
    <Layout>
        <section className="section is-medium">
            <div className="container content">
                <h1 className="title is-uppercase has-margin-bottom-1">Sobre <span className="has-text-danger">Nosaltres</span></h1>
                <h2 className="subtitle is-uppercase has-margin-bottom-1">Axioma Consultors Acústics</h2>
                <p className="is-size-5 pb-3">
                    Axioma Consultors Acústics SL, nascuda al 2001, és un despatx d’enginyeria, consultoria i assistència tècnica en matèria d’acústica ambiental i control del soroll.
                </p>
                <p className="is-size-5 pb-3">
                    Dona servei en diferents àmbits:
                </p>
                <ul className="is-size-5">
                    <li>Serveis de consultoria i assistència en matèria de soroll a empreses, indústries i entitats, per a la determinació i avaluació dels nivells de soroll i/o impacte acústic, la definició de programes de reducció de la contaminació acústica, el disseny de dispositius de sonorització, la tramitació d’expedients de legalització d’activitats i/o esdeveniments, mitjançant la elaboració dels preceptius estudis d’impacte acústic, etc.</li>
                    <li>El suport a Administracions Públiques en l’anàlisi, avaluació i gestió del soroll ambiental: mapes de soroll, mapes de capacitat acústica, ordenances de gestió soroll ambiental, programes d’assessorament i suport tècnic i formació, entitat d’inspecció, sonometries, dispositius de control i monitorització en continu de nivells de soroll, etc.</li>
                    <li>Serveis de suport tècnic al sector dels esdeveniments i l’oci, concretament en l’àmbit de l’anàlisi de l’impacte acústic, el control i la gestió del soroll, lligat a la celebració de concerts, revetlles, esdeveniments a l’aire lliure i activitats esportives, de tota mena de formats.</li>
                </ul>
                <p className="is-size-5 pb-3">
                    El grau d’especialització d’Axioma Consultors Acústics l’ha col·locat en una posició de referència entre les enginyeries i consultories tècniques del sector, i molt especialment, s’ha constituït com a líder predominant en serveis de predicció, control i monitorització del soroll per a la indústria i l’oci.
                </p>
                <p className="is-size-5 pb-3">
                    Axioma Consultors Acustics es una entitat de control i avaluació en l’àmbit de la prevenció de la contaminació acústica, EC-PCA i A-PCA.
                </p>
            </div></section>

    </Layout >
);

export default IndexPage;